import React, { useContext, useEffect, useState } from 'react'
import { NavigationType } from 'react-router-dom';
import { authContext } from '../../AuthContext'
import Constants from '../../commons/Constants';
import { useCheckRight } from '../../Components/CheckRight/CheckRight';
import { useP } from '../../services/i18n';


import HeaderContainer from './HeaderContainer'
import { useCheckFeatureFlag } from '../../Components/CheckFeatureFlag/CheckFeatureFlag';

function Header({ navigation }: Header.Props) {
  const {
    ROLE_ADMIN_CONTRAT,
    ROLE_ADMIN_BLUE_APPS
  } = Constants;
  const p = useP();

  const { loggedInUser, selectedCompte, roles, featuresFlag } = useContext(authContext);

  const [navigationData, setNavigationData] = useState<Array<NavigationType>>([])
  const [isProfilModaleOpen, setIsProfilModaleOpen] = useState(false)
  const [isComptesModaleOpen, setIsComptesModaleOpen] = useState(false)
  const [isDevicesModaleOpen, setIsDevicesModaleOpen] = useState(false)

  const { checkRight } = useCheckRight();
  const { checkFeatureFlag } = useCheckFeatureFlag();

  const roleAdminContrat = checkRight(ROLE_ADMIN_CONTRAT);
  const roleAdminBlueApps = checkRight(ROLE_ADMIN_BLUE_APPS);

  useEffect(() => {

    const defaultCompte = loggedInUser?.compte ? loggedInUser.compte.id : 0;

    let selected = defaultCompte;

    if (loggedInUser?.compte?.compteParent?.comptesEnfant) {
      const { compteParent } = loggedInUser.compte;

      if (!selectedCompte && compteParent.comptesEnfant.length) {
        selected = compteParent.comptesEnfant[0].id
      }
      else {
        const res = compteParent.comptesEnfant.find((record) => record.id === selectedCompte)
        selected = res ? res.id : loggedInUser?.compte?.id;
      }

    }

    const prefix = Array.isArray(selectedCompte) ? p.t("router.comptes.route") : p.t("router.compte.route")

    const data = navigation.filter((n) => checkRight(n.role) && (n.featureFlag ? checkFeatureFlag(n.featureFlag) : true)).map((nav) => {
      return {
        ...nav,
        href: nav.comptePrefix ? `${prefix}/${selected}${p.t(nav.href)}` : p.t(nav.href),
      }
    });

    setNavigationData(data);
  }, [loggedInUser, selectedCompte, roles, featuresFlag])

  const userNavigationAll = [
    { name: 'userNavigation.profil.label', href: 'userNavigation.profil.href', onClick: () => setIsProfilModaleOpen(true) },
    { name: 'userNavigation.releaseNotes.label', href: `userNavigation.releaseNotes.href` },
    { name: 'userNavigation.logout.label', href: 'userNavigation.logout.href' },
  ]

  const userNaviagationAdminContrat = [
    { name: 'userNavigation.profil.label', href: 'userNavigation.profil.href', onClick: () => setIsProfilModaleOpen(true) },
    { name: 'userNavigation.comptes.label', href: 'userNavigation.comptes.href', onClick: () => setIsComptesModaleOpen(true) },
    { name: 'userNavigation.releaseNotes.label', href: 'userNavigation.releaseNotes.href' },
    { name: 'userNavigation.devices.label', href: 'userNavigation.devices.href', onClick: () => setIsDevicesModaleOpen(true) },
    { name: 'userNavigation.logout.label', href: 'userNavigation.logout.href' },
  ]


  const getUserNavigation = () => {

    let navigation =  userNavigationAll;

    if (roleAdminContrat) {
      navigation = userNaviagationAdminContrat;
    }

    if(!roleAdminBlueApps){
      navigation = navigation.filter((nav) => nav.name !== 'userNavigation.devices.label')
    }
    
    return navigation;
  }

  return (
    <>
      <HeaderContainer
        navigation={navigationData}
        userNavigation={getUserNavigation()}
        user={loggedInUser}
        isProfilModaleOpen={isProfilModaleOpen}
        isComptesModaleOpen={isComptesModaleOpen}
        isDevicesModaleOpen={isDevicesModaleOpen}
        onCloseProfilModale={() => setIsProfilModaleOpen(false)}
        onCloseComptesModale={() => setIsComptesModaleOpen(false)}
        onCloseDevicesModale={() => setIsDevicesModaleOpen(false)}
      />
    </>
  )

}

export default Header